import React, { useContext, useState, useEffect, useRef } from 'react'
import SEO from '~/components/seo'
import StoreContext from '~/context/StoreContext'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'
import {
  GeneralGrid,
  GeneralGridMobile,
  SearchBarMobile,
  CloseIcons,
  ResultsTitleMobile,
  ClearTitleMobile,
} from '~/utils/styles'
import {
  ProductsWrapper,
  ProductTypeTitle,
  Product,
  Title,
  PriceTag,
  Img,
  ProductsDescription,
  ColorsDescription,
  ResultLength,
  ProductTypeTitleMobile,
  ProductTypeTitleMobileOther,
  ProductsWrapperMobile,
  ProductTypeTitleOther,
} from '../templates/ProductType/styles'
import Layout from '../layouts'
import Navigation from '../components/Navigation'
import Close from '../images/close.png'

const SearchPage = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const inputEl = useRef(null)

  const [searchResult, setSearchResult] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [resultLength, setResultLength] = useState(0)
  const [colorCircles, showColorCircles] = useState()

  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              productType
              createdAt
              description
              options {
                id
                name
                values
              }
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  const getSearchValue = searchTerm => {
    const results = allShopifyProduct
      ? allShopifyProduct.edges.filter(
          item =>
            item.node.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.node.productType
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.node.description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      : null
    setSearchResult(results)
    setSearchTerm(searchTerm)
    setResultLength(results.length)
  }

  const resetSearchResult = () => {
    setSearchResult('')
    setResultLength(0)
  }

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  useEffect(() => {
    // document.body.ontouchend = function() {
    //   document.querySelector('#input').focus()
    // }
  }, [])

  return (
    <div>
      <SEO
        title="BDST | BY BEN TAVERNITI | Bureau de Stil"
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />
      <BrowserView>
        <Layout>
          <GeneralGrid>
            <Navigation getSearchValue={getSearchValue} />
            {resultLength !== 0 ? (
              <ProductTypeTitle className="fade-in">
                {searchTerm.toUpperCase()} - {resultLength} RESULTS
              </ProductTypeTitle>
            ) : null}

            <ProductsWrapper className="fade-in">
              {searchResult
                ? searchResult.map(
                    ({
                      node: {
                        id,
                        handle,
                        title,
                        productType,
                        images: [firstImage],
                        variants: [firstVariant],
                        options,
                      },
                    }) => {
                      return (
                        <Product
                          key={id}
                          onMouseOver={() => showColorCircles(id)}
                          onMouseLeave={() => showColorCircles('')}
                        >
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/product/${handle}/`}
                          >
                            {firstImage && firstImage.localFile && (
                              <Img
                                style={{ objectFit: 'contain' }}
                                fluid={
                                  firstImage.localFile.childImageSharp.fluid
                                }
                                alt={handle}
                              />
                            )}
                            <ProductsDescription>
                              <Title>{title}</Title>
                              <PriceTag>
                                {getPrice(firstVariant.price)}
                              </PriceTag>
                            </ProductsDescription>
                            <ColorsDescription>
                              {options[1].values.map(item => (
                                <div
                                  className="hover-effect"
                                  style={{
                                    visibility:
                                      colorCircles == id ? 'visible' : 'hidden',
                                    background: '#' + item,

                                    borderRadius: '50%',
                                    width: '12px',
                                    height: '12px',
                                    margin: '0 10px 0 0',
                                    border: '0.5px solid black',
                                  }}
                                ></div>
                              ))}
                            </ColorsDescription>
                          </Link>
                        </Product>
                      )
                    }
                  )
                : null}
            </ProductsWrapper>
          </GeneralGrid>
        </Layout>
      </BrowserView>

      <MobileView>
        <Navigation />
        <SearchBarMobile
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          onChange={event => getSearchValue(event.target.value)}
          placeholder="Enter Search"
          style={{ textAlign: 'left', padding: '50px 0 0 0' }}
          ref={inputEl}
          id="input"
          autoFocus={true}
        />

        {/* <img
          onClick={() => window.history.back()}
          src={Close}
          alt={'close'}
          style={{
            width: '18px',
            height: '18px',
            position: 'fixed',
            top: '60px',
            right: '12px',
            zIndex: '201',
          }}
          onChange={event => getSearchValue(event.target.value)}
        /> */}

        <GeneralGridMobile style={{ margin: '10px 0 0 0' }}>
          {resultLength !== 0 ? (
            <ResultsTitleMobile className="fade-in">
              {resultLength} results
            </ResultsTitleMobile>
          ) : null}

          {resultLength !== 0 ? (
            <ClearTitleMobile
              onClick={() => resetSearchResult()}
              className="fade-in"
            >
              Clear
            </ClearTitleMobile>
          ) : null}

          <ProductsWrapperMobile className="fade-in">
            {searchResult
              ? searchResult.map(
                  ({
                    node: {
                      id,
                      handle,
                      title,
                      productType,
                      images: [firstImage],
                      variants: [firstVariant],
                      options,
                    },
                  }) => {
                    return (
                      <Product key={id}>
                        <Link to={`/product/${handle}/`}>
                          {firstImage && firstImage.localFile && (
                            <Img
                              style={{ objectFit: 'contain' }}
                              fluid={firstImage.localFile.childImageSharp.fluid}
                              alt={handle}
                            />
                          )}
                        </Link>
                        <ProductsDescription>
                          <Title>{title}</Title>
                          <PriceTag>{getPrice(firstVariant.price)}</PriceTag>
                        </ProductsDescription>
                        <ColorsDescription>
                          {options[1].values.map(item => (
                            <div
                              className="hover-effect"
                              style={{
                                background: '#' + item,

                                borderRadius: '50%',
                                width: '12px',
                                height: '12px',
                                margin: '0 10px 0 0',
                                border: '0.5px solid black',
                              }}
                            ></div>
                          ))}
                        </ColorsDescription>
                      </Product>
                    )
                  }
                )
              : null}
          </ProductsWrapperMobile>
        </GeneralGridMobile>
      </MobileView>
    </div>
  )
}

export default SearchPage
